import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuU_snX_Z9xgTW3m9NeNLRhJdrDsTnAVLyUg&s' className="App-logo" alt="logo" />
        <p>
          C'est super joli la grenouille, non ?
        </p>
      </header>
    </div>
  );
}

export default App;
